<template>
<div>
  <div class="loading-div" v-if="loading">
  <v-progress-circular style="display: relative; top:20%;"
      indeterminate
      color="primary"
      size=128
      width=8
    ></v-progress-circular>
  </div>
  <v-row class="fill-height ma-0" style="max-width: 100%">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <!--<v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>-->
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
           <v-spacer></v-spacer>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <!--<v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>-->
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :event-overlap-mode="mode"
          event-overlap-threshold="30"
          type="month"
          :weekdays="weekdays"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateEvent"
          locale="de"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon>
                <v-icon>mdi-liquor</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-glass-mug-variant</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
              <span v-html="selectedEvent.start_b"></span> - <span v-html="selectedEvent.end_b"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
  <div id="contact">
    <p>Terminanfragen bitte an Jürgen Rehkemper.</p>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  data: () => ({
    loading: true,
    datetime: null,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    weekdays: [
      1, 2, 3, 4, 5, 6, 0
    ],
    mode: "stack"
  }),
  mounted() {
    //console.log(process.env.VUE_APP_API)
    axios.post(process.env.VUE_APP_API+"/getBierwagenKalender")
      .then((response) => {
        this.loading = false
        response.data.forEach(element => {
          var name = element["SUMMARY"] || "unbenannt"
          var start = this.convertDate(element["DTSTART;TZID=Europe/Berlin"])
          var start_b = this.convertBeautyDate(element["DTSTART;TZID=Europe/Berlin"])
          var end = this.convertDate(element["DTEND;TZID=Europe/Berlin"])
          var end_b = this.convertBeautyDate(element["DTEND;TZID=Europe/Berlin"])
          // Handle FullDay Events
          if(start == 'Invalid date') {
            //console.log("Detect Full-Day Event")
            start = this.convertDate(element["DTSTART;VALUE=DATE"])
            start_b = this.convertBeautyDate(element["DTSTART;VALUE=DATE"])
            end = this.convertDate(element["DTEND;VALUE=DATE"])
            end = this.convertDate(moment(end).subtract(1, 'minute'))
            end_b = this.convertBeautyDate(moment(end))
          } 
          this.insertEvent(name, start, start_b, end, end_b, this.colors[0], false)
        });
      })
    this.$refs.calendar.checkChange();
    
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    convertDate(oldDate) {
      //console.log("convertDate: "+oldDate)
      return moment(String(oldDate)).format('YYYY-MM-DD HH:mm')
    },
    convertBeautyDate(oldDate) {
      return moment(String(oldDate)).format('DD.MM.YYYY HH:mm')
    },
    insertEvent(name, start, start_b, end, end_b, color, timed)
    {
      console.log("Event "+name+": "+start+" - "+end)
        this.events.push({
            name: name,
            start: start,
            start_b: start_b,
            end: end,
            end_b: end_b,
            color: color,
            timed: timed,
        })
    },
    updateEvent() {
        //console.log("update")
    }
  },
};
</script>
<style scoped>
#contact {
  display: flex;
  justify-content: center;
}

.loading-div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  vertical-align: center;
  z-index: 10;
  background-color: rgba(0,0,0,0.4);
}
</style>