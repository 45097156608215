<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <img style="width: 120px;" src="@/assets/NachBar_Logo.png"><img>
        <!--<v-icon large>mdi-glass-mug-variant</v-icon>-->
        <h2 class="pl-3">- Kalender</h2>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
    //
  }),
};
</script>

<style>
#app {
  background-image: url("@/assets/NachBar_Logo.png");
  background-size: 90vw, 90vw;
  background-repeat: no-repeat;
  background-position: center center;
}

.row {
  opacity: 0.95;
}
</style>
